import React, { useState } from 'react'

interface Props {
  title: string;
  value: number | string;
  notdisplayed: boolean;
  optionsArray: any;
  disabledArray: number[];
  optionsByDependency: number[];
  handleChange: (value: any) => void;
  disabled?: boolean;
  translate?: boolean
}

const ImageSelect = (props: Props) => {

  // console.log('props:', props)

  const [hidden, setHidden] = useState<boolean>(true)

  const options = props.optionsArray.filter((obj: any) => props.optionsByDependency.includes(obj.id))

  console.log(props.title, ' --- options:', options)

  const handleSelect = ((value: string) => {
    props.handleChange(value)
    setHidden(!hidden)
  })


  return (
    <div>
      <label className={(props.optionsByDependency.length === 0 || props.notdisplayed) ? 'red' : 'green'} onClick={e => setHidden(!hidden)}>{props.title}: </label>
      <span className={`${(props.optionsByDependency.length === 0 || props.notdisplayed) ? 'hide-select-options' : ''} `}><i className='arrow down ' onClick={e => setHidden(!hidden)}></i></span>

      <div id={props.title} className={`${hidden ? 'hide-select-options' : ''}`}  >
        {options.map((opt: any, index: number) =>
          <div key={opt.id + index} onClick={e => handleSelect(opt.value)}>
            <img className="image-select-option" src={'data:image/png;base64,' + opt.bildBLOB} alt={opt.beschriftungId} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageSelect;
