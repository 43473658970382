
interface Props {
  title: string;
  value: number | string;
  notdisplayed: boolean;
  optionsArray: any;
  disabledArray: number[];
  optionsByDependency: number[];
  handleChange: (value: any) => void;
  disabled?: boolean;
  translate?: boolean
}

const LabeledSelect = (props: Props) => {

  // console.log('props:', props)

  const options = props.optionsArray.filter((obj: any) => props.optionsByDependency.includes(obj.id))

  // console.log(props.title, ' --- options:', options)

  return (
    <div>
      <label className={(props.optionsByDependency.length === 0 || props.notdisplayed) ? 'red' : 'green'}>{props.title}: </label>
      <select name={props.title} id={props.title} disabled={props.disabled} onChange={e => props.handleChange(e.target.value)} value={props.value}>
        {options.map((opt: any, index: number) => <option key={opt.label + index} value={opt.value} disabled={props.disabledArray.includes(opt.id)} >{props.translate ? 't(' + opt.label + ')' : opt.label}</option>)}


      </select>
    </div>
  );
};

export default LabeledSelect;
