import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import TabByJSON from "./tabByJSON/tabbyjson";


function App() {

  const [textareaContent, setTextareaContent] = useState<string>("{}")
  const [trigger, setTrigger] = useState<boolean>(true)

  return (
    <div className="App">

      <Router>

        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
          </ul>
        </nav>

        <div id="app-container">

          <div className="input-area">
            <div className="input-label-container">
              <label>JSON:</label>
            </div>
            <textarea
              name="postContent"
              value={textareaContent}
              onChange={e => setTextareaContent(e.target.value)}
            // rows={4} cols={40}
            />
            <button onClick={() => setTrigger(!trigger)}>rendern!</button>
          </div>
          <div className="output-area">
            <Routes>

              <Route path="/" element={<TabByJSON elements={textareaContent} trigger={trigger} />} ></Route>

            </Routes>
          </div>


        </div>
      </Router>


    </div>
  );
}

export default App;
